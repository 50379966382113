
  .Footer {
    position: sticky;
    background-color: var(--colour-core-dark-blue);
    width: 100vw;
    height: 5vh;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    bottom: 0;
    min-height: 50px;
  }

  .Footer-link {
    cursor: pointer;
    font-weight: bold;
    height: 5vh;
    color: white;
    margin-left: 2vh;
  }

  .Footer-contact {
    cursor: pointer;
    font-weight: bold;
    height: 5vh;
    color: white;
    margin-left: 2vh;
  }

  .Footer-terms {
    cursor: pointer;
    font-weight: bold;
    height: 5vh;
    color: white;
    margin-right: 2vh;
  }
