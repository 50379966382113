.Modal {
    overflow: "hidden";
    max-width: 100%;
    overflow-x: hidden;
}

/* .Modal .Header {
} */

.DialogTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 10%;
    border-bottom: 1px solid gray;
    background-color: var(--colour-core-blue);
    font-size: 2vw;
    color: white;
}

.DialogTitle .Title {
    font-size: 2vw;
    color: white;
    padding:0px;
    margin-top: auto;
    margin-bottom: auto;
}

.DialogTitle .Close {
    cursor: pointer;
    /* float: right; */
    height: 20px;
    width: 20px;
    padding: 20px;
    position: 'absolute';
    right: 8;
    top: 8;
}

.Modal .Content {
    width: 100%;
    /* padding: 10px 5px; */
    font-size: 12px;
    overflow: "hidden";
    max-width: 100%;
    overflow-x: hidden;
    text-align: center;
}

.Modal .Text {
    text-align: center;
    border-bottom: solid;
    border-width: thin;
    padding-bottom: 2vh;
}

/* .Modal .Actions {
    width: 100%;
    padding: 10px 5px;
    margin: auto;
    text-align: center;
} */

.Modal .Actions .File-button {
    margin-bottom: 1vh;
}

.Modal .Actions .Upload-button {
    margin-bottom: 1vh;
    margin-right: 2vh;
}

.Card {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    
}

.Card .Index {
    margin: 5px
}