

  .bm-burger-button {
    position: sticky;
    min-height: 30px;
    min-width: 36px;
    width: 5vh;
    height: 4vh;
    margin: calc(15px + 1vh);
  }
  
  .bm-burger-bars {
    background: var(--colour-core-dark-blue);
  }
  
  .bm-burger-bars-hover {
    background: var(--colour-highlight-orange);
  }
  
  .bm-cross-button {
    width: 36px;
    height: 36px;
    right: 36px;
    top: 36px;
  }
  
  .bm-cross {
    display: none
  }
  
  .bm-menu-wrap {
    position: fixed;
    height: 100%;
  }
  
  .bm-menu {
    background-color: var(--colour-foundation-grey-2);
    
    @media (min-width: 900px) {
      padding: 2vw 2.5vw 0;
      margin-top: 0.8vh;
    }
    @media (max-width: 899px) {
      padding: 2.4em 2.5em 0;
      margin-top: -0.4vh;
    }

    font-size: 1.8em;
    z-index: 1;
    position: relative;
  }

  .menu-item {
    text-decoration: none;
    color: white;
    font-weight: bold;
    line-height: 3;
    font-size: calc(14px + 0.1em);
  }
