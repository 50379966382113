.ViewPage {
    /* position: relative; */
  display: grid;
  grid-template-areas:
    'header header header header header header header header header header'
    'details details details details details details details details details upload'
    'table table table table table table table table table table'
    'table table table table table table table table table table'
    'table table table table table table table table table table'
    'table table table table table table table table table table'
    'table table table table table table table table table table'
    'table table table table table table table table table table'
    'table table table table table table table table table table'
    'table table table table table table table table table table';
  /* flex-direction: column; */
  /* overflow: 'scroll' */
}

.ViewPage > .Header {
    background-color: var(--colour-core-dark-blue);
    width: calc(100% - 20px);
    min-height: 60px;
    max-height: 20%;
    min-width: fit-content;
    height: fit-content;
    display: flex;
    justify-content: space-between;
    grid-area: header;
    padding: 10px;
    position: sticky;
    top:0;
    z-index: 9999;
}

.ViewPage > .Header > .Text {
    text-align: left;
    color: white;
    font-size: 1em;
    margin-top:auto;
    margin-bottom:auto;
    float: left;
}

.ViewPage > .Header > .Load {
    float: left;
    font-size: 1.5vw;
    margin: 0.75vw;
    margin-top:auto;
    margin-bottom:auto;
    text-align: center;
    height: 5vh;
    flex: left;
}
  
.ViewPage > .Header > .Close {
    cursor: pointer;
    background-color: var(--colour-core-blue);
    float: right;
    color: white;
    font-size: 1.5vw;
    font-weight: bold;
    margin: 5px;
    text-align: center;
    height: 5vh;
    min-height: 50px;
}

.BreadcrumbDiv {
    text-align: center;
    min-width: 0;
    flex-basis: 70%;
    margin-left: 1em;
    padding:0px;
    margin-top: auto;
    margin-bottom: auto;
}

.Breadcrumb {
    margin-top:auto;
    margin-bottom:auto;
}

.BreadcrumbsLink {
    cursor: pointer;
    line-height: 100%;
    text-align: left;
    color: lightgray;
    font-size: 4vh;
    margin-top:auto;
    margin-bottom:auto;
}

.BreadcrumbsText {
    line-height: 100%;
    text-align: left;
    color: white;
    font-size: 4vh;
    margin-top:auto;
    margin-bottom:auto;
}

.MuiBreadcrumbs-separator{
    color: white;
    font-size: 4vh;
}

.ViewPage > .DetailsAccordion {
    margin: 0;
    padding: 0;
    grid-area: details
}

.ViewPage > .DetailsAccordion > .Summary {
    margin: 0;
    padding: 0;
    height: fit-content;
    height: 5vh;
}

.Details {
    /* height: 10px; */
    margin: 0;
    padding: 0;
    /* background-color: red; */
}

.Details > .TextDiv {
    margin: 0;
    margin-left: 10px;
    padding: 0;
    /* background-color: green; */
    height: 100%;
    width: 100%;
}

.Details > .TextDiv > .Text > .Strong {
    margin: 0;
    padding: 0;
    font-size: 1em
    /* background-color: blueviolet; */
}

.Details > .TextDiv > .Text {
    padding: 0;
    font-size: 1em
    /* margin: 0; */
    /* line-height: 10px; */
    /* margin: 0; */
}

.ViewPage > .Upload {
    cursor: pointer;
    margin: 0.5vw; 
    font-weight: bold;
    height: 5vh;
    min-height: 50px;
    margin-left: auto;
    grid-area: upload;
    /* background-color: var(--colour-core-dark-blue);
    color: white; */
}

.TableHeader {
    font-style: normal;
}

.Table {
    grid-area: table;
}