@import url("Colours.css");

.font-light {
    font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif; 
    font-size: 1.25vw;
}
  
.font-medium {
    font-family: 'Helvetica Neue', Helvetica, Arial, "Lucida Grande", sans-serif; 
    font-weight: medium;
    font-size: 2vw;
}

.font-header {
    font-family: 'Helvetica Neue', Helvetica, Arial, "Lucida Grande", sans-serif; 
    font-weight: medium;
    font-size:calc(12px + 1.7vw);
    color: var(--colour-core-dark-blue);
}

.font-footer {
    font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif; 
    font-size: 1em;
    color: white;
    text-decoration: none;
}