.ExamPage {
    position: relative;
    z-index: 0;
}

.ExamPage-table {
    height: 100%;
    width: 100%;
    position: relative;
    z-index: 0;
}

.TableHeader {
    font-style: normal;
}