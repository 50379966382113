.Card {
    min-height: '60vh';
    overflow: 'auto';
    
}

.Card .Grid {
    height: "100%";
    display: "flex";
    align-items: "center";
    box-shadow: 0;
}

.Card .Grid .Grid .Item {
    box-shadow: 0;
}

.TermsTitle {
    text-align: center
}

.TermsSubtitle {
    text-align: center
}
