/* .DeletConfirmationDialog {

} */

.DeletConfirmationDialog .Title {
    background-color: var(--colour-core-dark-blue);
    color: white
}

/* .DeletConfirmationDialog .Content {
} */

.DeletConfirmationDialog .Content .Text {
    margin-top: 2vh
}

/* .DeletConfirmationDialog .Cancel {

} */
