@import url("Colours.css");
@import url("Fonts.css");
@import url("PageHeader.css");
@import url("PageFooter.css");
@import url("ExamPage.css");
@import url("ViewPage.css");
@import url("Modals.css");
@import url("TermsAndConditions.css");
@import url("BurgerMenu.css");
@import url("DeleteConfirmationDialog.css");

.App {
  background-color: var(--colour-foundation-grey-5);
  position: fixed;
  height: 100%;
  width: 100%;
  display:flex;
  flex-direction: column;
}

.App-body {
  width: 100%;
  height: 85%;
  position: sticky;
  top: 10vh;
}

.Page {
  position: absolute;
  background-color: white;
  height: 92%;
  width: 96%;
  margin: 2%;
  overflow: auto;
}

.Progress {
  margin: auto;
  text-align: center;
}

.Loading {
  /* margin: auto; */
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}

.Alert {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}