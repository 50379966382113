
  .Header {
    position: sticky;
    background-color: white;
    width: 100%;
    height: 10vh;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    vertical-align: middle;
    min-height: 70px;
    z-index: 1;
    justify-content: flex-end;
  }
  
  .Header-logo-link {
    color: inherit;
    cursor: pointer;
  }

  .Header-logo {
    width: 15vh;
    height: 10vh;
    min-height: 70px;
    min-width: 100px;
    border-right: solid;
    border-width: thin;
    float: left;
    position: absolute; 
    left: 0; 
  }
  
  .Header-title {
    text-align: center;
    min-height: fit-content;
    min-width: fit-content;
    flex-basis: 70%;
    margin-top: auto;
    margin-bottom: auto;
    position: absolute; 
    left: 50%; 
    top: calc(10px + 1vh);
    transform: translateX(-50%);
  }

  .Header-howto {
    cursor: pointer;
    height: 5vh;
    min-height: 45px;
    min-width: 50px;
    padding-left: 10px;
    padding-right: 10px;
    background-color: var(--colour-core-blue);
    color: white;
    float: right;
    margin: 2vh;
    margin-right: 1vh;
    flex-basis: 10%;
    font-size: calc(10px + 0.1em);
  }
  
  .Header-updateprofile {
    cursor: pointer;
    height: 5vh;
    min-height: 45px;
    min-width: 70px;
    padding-left: 5px;
    padding-right: 5px;
    width: 60px;
    background-color: var(--colour-core-dark-blue);
    color: white;
    float: right;
    margin: 2vh;
    margin-left: 0vh;
    margin-right: 1vh;
    flex-basis: 6%;
    font-weight: bold;
    font-size: calc(10px + 0.1em);
  }
  
  .Header-changepassword {
    cursor: pointer;
    height: 5vh;
    min-height: 45px;
    min-width: 100px;
    padding-left: 10px;
    padding-right: 10px;
    background-color: var(--colour-core-blue);
    color: white;
    float: right;
    margin: 2vh;
    margin-left: 0vh;
    margin-right: 1vh;
    flex-basis: 6%;
    font-weight: bold;
    font-size: calc(10px + 0.1em);
  }
  
  .Header-logout {
    cursor: pointer;
    height: 5vh;
    width: fit-content;
    min-height: 45px;
    min-width: 60px;
    padding-left: 10px;
    padding-right: 10px;
    background-color: var(--colour-core-dark-blue);
    color: white;
    float: right;
    margin: 2vh;
    margin-left: 0vh;
    text-align: center;
    flex-basis: 6%;
    font-weight: bold;
    font-size: calc(10px + 0.1em);
  }
