:root {
    --colour-core-blue: #2EAEE4;
    --colour-core-green: #25C7B7;
    --colour-core-dark-blue: #111D5E;
    --colour-core-black: #0C1545;
    --colour-highlight-orange: #F58220;
    --colour-highlight-green: #9ACC6A;
    --colour-highlight-yellow: #FFC82F;
    --colour-foundation-grey-1: #396678;
    --colour-foundation-grey-2: #5D8395;
    --colour-foundation-grey-3: #85A4B3;
    --colour-foundation-grey-4: #B2C7D3;
    --colour-foundation-grey-5: #D1DFDD;
  }